
import { defineComponent, ref, onMounted } from "vue";
import { CreateOrEditAgentDto } from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import useAgents from "@/composables/useAgents";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AgentForm from "@/components/Forms/AgentForm.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  components: {
    AgentForm,
  },
  setup() {
    const defaultAgent = ref({
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      territoryId: "",
    } as CreateOrEditAgentDto);
    const hold = ref(JSON.parse(JSON.stringify(defaultAgent.value)));

    const { updateOrCreateAgent } = useAgents();
    const store = useStore();

    const saving = ref(false);

    const submit = async () => {
      saving.value = true;
      await updateOrCreateAgent(defaultAgent.value);
      const error = store.getters.getErrors;
      saving.value = false;
      if (!error) {
        Swal.fire({
          text: "Agent created successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        }).then(function () {
          defaultAgent.value = { ...hold };
          // router.push({ name: "customers" });
        });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Agents", ["Agent", "Create"]);
    });

    return {
      defaultAgent,
      saving,
      submit,
    };
  },
});
